<template>
  <div class="mpz-map" v-if="showMap">
    <div class="info-block">
      <div class="title title-3">Мы тут</div>
      <div class="text">Ишимбайский район,Урман-Бишкадарское сельское поселение,5 км автодороги Ишимбай-Красноусольск</div>
    </div>
    <yandex-map
      :settings="settings"
      ref="ym"
      class="ymap"
      :coords="coords"
      :zoom="13"
      :behaviors="['drag']"
      :controls="['zoomControl']"
    >
      <ymap-marker
        v-for="(marker, index) in markers"
        :key="index"
        :coords="marker"
        :marker-id="index"
      />
    </yandex-map>
  </div>
</template>

<script scoped setup>
import { onMounted, ref, reactive } from 'vue';
import { loadYmap, yandexMap, ymapMarker } from 'vue-yandex-maps'

const settings = reactive({
  apiKey: '352a563c-8a19-41b4-97df-1effc3d4151e',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
})

const showMap = ref(false)

onMounted(async() => showMap.value = true)

const coords = ref([53.489786, 56.063786])

const markers = ref([
  [53.483181, 56.063401]
])
</script>

<style lang="scss">
.mpz-map{
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid #EAEAEA;
  border-radius: 30px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  .info-block{
    position: absolute;
    z-index: 2;
    padding: 30px;
    width: 100%;
    background: #FFFFFF;
    backdrop-filter: blur(5px);
    border-bottom: 1px solid #EAEAEA;
    .title{
      color: #232323;
      font-family: 'SFProDisplay-Medium';
    }
    .text{
      margin-top: 30px;
      line-height: 130%;
    }
  }
}

.ymap{
  width: 100%;
  height: 100%;
}


#yandexMap66605,
.ymaps-2-1-79-map,
.ymaps-2-1-79-inner-panes,
.ymaps-2-1-79-events-pane{
  border-radius: 30px !important;
}

.ymaps-2-1-79-gototaxi,
.ymaps-2-1-79-gototech,
.ymaps-2-1-79-copyright__content-cell{
  display: none !important;
}

.ymaps-2-1-79-controls__control{
  inset: 210px auto auto 10px !important;
}
.ymaps-2-1-79-map-copyrights-promo{
  left: 15px !important;
}
// .ymaps-2-1-79-map-copyrights-promo,
// .ymaps-2-1-79-copyright__wrap{
//   display: none !important;
// }
// .ymaps-2-1-79-ground-pane {
// 	filter: invert(85%);
// 	-ms-filter: invert(85%);
// 	-webkit-filter: invert(85%);
// 	-moz-filter: invert(85%);
// 	-o-filter: invert(85%);
// }
</style>
